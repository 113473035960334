import React, { Component } from "react"
import { Link } from "gatsby"
import { Helmet } from 'react-helmet'
import Layout from "../components/layout"
import SEO from "../components/seo"
import AlertsSlider from "../components/alertsslider"
import SearchForm from "../components/searchform"
import Meeting from '../components/meeting.js'
import $ from "jquery"
// import YtViewCount from "../components/ytviewcount"
// import YtDatePublished from "../components/ytdatepublished"

class IndexPage extends Component {

  constructor() {
    var today = new Date();
    var month = (today.getMonth() + 1).toString();
    if (month.length < 2) {
      month = `0${month}`;
    }
    var day = today.getDate().toString();
    if (day.length < 2) {
      day = `0${day}`;
    }

    var date = today.getFullYear() +''+month+ ''+day;
    super();
    this.state = {
      currentDateTime: date,
    }
  }

  render() {
    const currentPage = this.props.data.nodeHomepage
    const relatedEvents = this.props.data.allNodeEvent
    const relatedNews = this.props.data.allNodeNews
    const alerts = this.props.data.allNodeAlert
    const meta = currentPage.relationships.field_meta_data
    const allLandingPages = this.props.data.allNodeLandingPage.edges
    var moment = require('moment'); // require

    var filterEvents = relatedEvents.edges.filter(item => moment(item.node.field_start_date).format('YYYYMMDD') >= this.state.currentDateTime );
    filterEvents = filterEvents.slice(0,6);

    var landingPageRel = []
    var landningGroup
    allLandingPages && allLandingPages.map((page,key) => (
      page.node.relationships.group_content__group_content_type_20b7e70e3cc2b ? (
        landningGroup = page.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id,
        landingPageRel[landningGroup] = [],
        landingPageRel[landningGroup]['title'] = page.node.relationships.field_hero.field_title_html.value,
        landingPageRel[landningGroup]['path'] = page.node.path.alias

      ) : (
        null
      )))

    return (
      <Layout>
        <section className="hero-area-frontpage" style={{backgroundImage: `url(${currentPage.relationships.field_home_hero.relationships.field_media_image.relationships.field_media_image.uri.url})`}}>
          <SEO
              title="Prince William County Government"
              metaData={meta}
              locationOrigin={this.props.location.origin + "/"}
              domen={this.props.location.origin}
          />
            <AlertsSlider allAlerts={alerts.edges} gruopId={null} globalPage={true} />

          <div className="site-headline">
            <div className="container">
              <div className="uk-grid" data-uk-grid>
                <div className="uk-width-2-3@s shield-with-title">
                  <div className="uk-grid uk-flex-middle" data-uk-grid>
                    <div className="headline-box">
                      <img src={currentPage.relationships.field_home_hero.relationships.field_icon.uri.url} alt="shield" />
                      <h1 dangerouslySetInnerHTML={{ __html: currentPage.relationships.field_home_hero.field_title_html.value }} />
                    </div>
                  </div>
                  <SearchForm placeholder="What are you looking for today?" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {currentPage.relationships.field_tabbed_lists.length > 0 ? (
          <section className="tab-section">
            <div className="container">
              <ul className="uk-grid uk-child-width-1-5 uk-subnav uk-subnav-pill uk-flex tab-section-list" data-uk-grid data-uk-switcher="animation: uk-animation-fade">
                {currentPage.relationships.field_tabbed_lists.map((tab, index) => (
                  <li key={index}><a className={tab.field_tab_icon_name} href="/#" title={tab.field_tab_icon_name} aria-label={tab.field_title} >{tab.field_title}</a></li>
                ))}
              </ul>

              <div className="uk-switcher tab-content">
                {currentPage.relationships.field_tabbed_lists.map((tab, index) => (
                  tab.__typename === "paragraph__tab_type_1" ? (
                    <div className="uk-grid uk-child-width-1-2 uk-child-width-1-4@m" data-uk-grid key={index}>
                      {tab.relationships.field_list_items.map((item, index_item) => (
                        <Link to={item.field_link !== null ? ( `${item.field_link.uri.replace('internal:','')}` ) : ( '#' )} className="uk-card uk-flex" key={index_item}>
                          <div className="uk-width-auto card-image">
                            <img src={item.relationships.field_icon.uri.url} alt={item.relationships.field_icon.filename.substring(0,item.relationships.field_icon.filename.indexOf('.')).replace(/-|_/g,'')} />
                          </div>
                          <div className="uk-width-expand card-content">
                            <h2 className="h3">{item.field_title}</h2>
                            <p>{item.field_description}</p>
                          </div>
                        </Link>
                      ))}
                    </div>
                  ) : (
                    index > 2 ? (
                      <div className="simple-list uk-child-width-1-2@s uk-child-width-1-3@m" data-uk-grid key={index}>
                        {tab.relationships.field_list_items_simple.map((item, index_item) => (
                          <div key={index_item}>
                            <a href={item.field_link.uri.replace('internal:', '')}>{item.field_link.title}</a>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="simple-list uk-grid uk-child-width-1-2@s uk-child-width-1-4@m" data-uk-grid key={index}>
                        {tab.relationships.field_list_items_simple.map((item, index_item) => (
                          <div key={index_item}>
                            <a href={item.field_link.uri.replace('internal:', '')}>{item.field_link.title}</a>
                          </div>
                        ))}
                      </div>
                    )
                  )
                ))}
              </div>
            </div>
          </section>
        ) : (
          null
        )}

        <section className="media-section">
          <div className="container">

            <ul className="uk-grid uk-child-width-1-3 uk-child-width-1-4@m uk-subnav uk-subnav-pill uk-flex tab-media-list" data-uk-grid data-uk-switcher="animation: uk-animation-fade; swiping: false;">
              <li><a className="news" title="news" aria-label="news" href="/#">News</a></li>
              <li><a className="events" href="/#" aria-label="events" title="events">Events</a></li>
              <li><a className="social-media" href="/#" title="social media" aria-label="social media">Social Media</a></li>
            </ul>

            <div className="uk-switcher tab-content">
              <div id="home-news-tab-content" className="tab-slider-wrp uk-position-relative uk-visible-toggle " tabIndex="-1" data-uk-slider>
                <div className="uk-slider-container">
                  <ul className="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-small uk-grid uk-grid-match" data-uk-grid>
                    {relatedNews.edges.map((news, index) => (
                      <li key={index}>
                        <div className="uk-card uk-flex uk-flex-column news-home-card">
                          <div className="inner-card">
                            <Link to={news.node.path.alias} aria-label={news.node.title + " open card"}>
                              {news.node.relationships.field_media_image && news.node.relationships.field_media_image.relationships.field_media_image ? (
                                <div className="uk-card-media-top" style={{backgroundImage: `url(${news.node.relationships.field_media_image.relationships.field_media_image.uri.url}`}}></div>
                              ) : (
                                <div className="uk-card-media-top"></div>
                              )}

                            </Link>
                            <div className="uk-card-body">
                              <Link to={news.node.path.alias}><h2 className="h3">{news.node.title}</h2></Link>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <a className="uk-position-center-left-out uk-position-small" href="#" data-uk-slidenav-previous data-uk-slider-item="previous" aria-label="previous news"></a>
                <a className="uk-position-center-right-out uk-position-small" href="#" data-uk-slidenav-next data-uk-slider-item="next" aria-label="next news"></a>
                <div className="button-wrp">
                  <a className="uk-button green-bgr" href="/news">All News</a>
                </div>
              </div>

              <div id="home-events-tab-content" className="tab-slider-wrp uk-position-relative uk-visible-toggle" tabIndex="-1" data-uk-slider>
                <div className="uk-slider-container">

                  <ul className="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-small uk-grid uk-grid-match" data-uk-grid>
                    {filterEvents && filterEvents.map((eventItem, index) => (

                        <li key={index}>
                          <div className="uk-card uk-flex uk-flex-column events-home-card">
                            <div className="inner-card uk-flex uk-flex-column">
                              <div className="uk-card-header">
                                {moment(eventItem.node.field_start_date).format("dddd, MMMM D")}
                              </div>
                              <div className="uk-card-body">
                                <Link to={eventItem.node.path.alias}><h2 className="h5">{eventItem.node.title}</h2></Link>
                              </div>
                              <div className="uk-card-footer">
                                {eventItem.field_virtual_event === true ? (
                                  <div className="location">Virtual Event</div>
                                ) : (
                                  <React.Fragment>
                                    {eventItem.node.field_start_date && eventItem.node.field_start_date ? (
                                      <div className="time">{moment(eventItem.node.field_start_date).format("hh:mm A") + " - "+ moment(eventItem.node.field_end_date).format("hh:mm A")}</div>
                                    ) : (
                                      null
                                    )}
                                    {eventItem.node.field_address_line_2 != null && eventItem.node.field_address_line_1 != null ? (
                                      <div className="location">{eventItem.node.field_address_line_1 + ", " + eventItem.node.field_address_line_2}</div>
                                    ) : (
                                      eventItem.node.field_address_line_1 != null ? (
                                        <div className="location">{eventItem.node.field_address_line_1}</div>
                                      ) : (
                                        null
                                      )
                                    )}
                                  </React.Fragment>
                                )}

                                {eventItem.node.relationships.group_content__departments_group_node_event && eventItem.node.relationships.group_content__departments_group_node_event.length > 0 ? (
                                  eventItem.node.relationships.group_content__departments_group_node_event.map((group,key) => (
                                    landingPageRel[group.relationships.gid.drupal_internal__id] !== undefined ? (
                                      <Link className="landing-page-link" key={"grouplink"+key} to={landingPageRel[group.relationships.gid.drupal_internal__id]['path'] + '/events'}>{landingPageRel[group.relationships.gid.drupal_internal__id]['title']}<br /></Link>
                                    ) : (
                                      <Link className="landing-page-link" to="/events">County Events</Link>
                                    )
                                  ))
                                ) : (
                                  <Link className="landing-page-link" to="/events">County Events</Link>
                                )}

                              </div>
                            </div>
                          </div>
                        </li>

                    ))}
                  </ul>
                </div>
                <a className="uk-position-center-left-out uk-position-small" href="#" data-uk-slidenav-previous data-uk-slider-item="previous" aria-label="previous item events"></a>
                <a className="uk-position-center-right-out uk-position-small" href="#" data-uk-slidenav-next data-uk-slider-item="next" aria-label="next item events"></a>
                <div className="button-wrp">
                  <Link className="uk-button green-bgr" to="/events">All Events</Link>
                </div>
              </div>

              <div id="home-social-tab-content" className="tab-slider-wrp uk-position-relative uk-visible-toggle" tabIndex="-1">
                <div>
                  <Helmet>
                    <script async defer src="https://platform.twitter.com/widgets.js" charSet="utf-8"/>
                  </Helmet>
                  <ul className="twitter-timeline-wrapp">
                    {/*<li>
                      <a data-pin-do="embedUser" data-pin-board-width="100%" data-pin-scale-height="600" data-pin-scale-width="80" href="https://www.pinterest.com/VisitPrinceWilliamVA"></a>
                    </li>
                    <li>
                      <blockquote className="instagram-media" data-instgrm-version="7" >
                        <a href="https://www.instagram.com/p/CLunu0YnbPO/"></a>
                      </blockquote>
                    </li>*/}
                    <li>
                      <a className="twitter-timeline" data-tweet-limit="3" href="https://twitter.com/pwcgov?ref_src=twsrc%5Etfw">Tweets by PWCGOV</a>
                    </li>
                   {/* <li>
                     <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FPWCGOV%2F&tabs=timeline&width=291&height=1000&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=774883273048075" scrolling="no" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                    </li>
                    <li>
                      <iframe src="http://www.youtube.com/embed/?listType=user_uploads&list=PrinceWilliamCounty" width="480" height="400"></iframe>
                    </li>*/}
                  </ul>
                </div>
              </div>
              <a className="uk-position-center-left-out uk-position-small" href="#" data-uk-slidenav-previous data-uk-slider-item="previous" aria-label="previous item social media"></a>
              <a className="uk-position-center-right-out uk-position-small" href="#" data-uk-slidenav-next data-uk-slider-item="next" aria-label="next item social media"></a>
              <div className="button-wrp">
                <a className="uk-button green-bgr" href="#">Social Media</a>
              </div>
            </div>
          </div>
        </section>

        {currentPage.relationships.field_board_of_supervisors ? (
          <section className="board">
            <div className="container">
              <h2>{currentPage.relationships.field_board_of_supervisors.field_title}</h2>
              {currentPage.relationships.field_board_meetings && currentPage.relationships.field_board_meetings !== null ? (
                currentPage.relationships.field_board_meetings.filter(meeting => meeting.field_show_this_meeting === true).map((meeting, mkey) => (
                  mkey === 0 && meeting.field_link.uri == 'https://pwcgov.granicus.com/ViewPublisher.php?view_id=7' ? (
                    <Meeting boardLink={meeting.field_link} boardShow={meeting.field_show_this_meeting} />
                  ) : (
                    null
                  )
                ))
              ) : (
                null
              )}
              <div className="uk-grid uk-child-width-1-3@m" data-uk-grid>
                <div>
                  {currentPage.relationships.field_board_of_supervisors.relationships.field_media_image ? (
                    <img src={currentPage.relationships.field_board_of_supervisors.relationships.field_media_image.relationships.field_media_image.uri.url} alt="board-supervisors" />
                  ) : (
                    null
                  )}

                </div>
                <div className="board-list">
                  {currentPage.relationships.field_board_of_supervisors.relationships.field_links && currentPage.relationships.field_board_of_supervisors.relationships.field_links.map((link,key) => (
                    link.relationships.field_icon ? (
                      <a href={link.field_link.uri.replace('internal:','')} key={'link'+key} ><div className="icon-wrp"><img src={link.relationships.field_icon.uri.url} alt="board-icon"/></div>{link.field_link.title}</a>
                    ) : (
                      <a href={link.field_link.uri.replace('internal:','')} key={'link'+key}><div className="icon-wrp"></div>{link.field_link.title}</a>
                    )

                  ))}

                </div>
                <div className="uk-divider-vertical">
                  <h3>{currentPage.relationships.field_board_of_supervisors.field_title_2}</h3>
                  <div dangerouslySetInnerHTML={{ __html: currentPage.relationships.field_board_of_supervisors.field_description_wysiwyg.processed}}/>
                  {currentPage.relationships.field_board_of_supervisors.field_link ? (
                    <div className="button-wrp">
                      <a className="uk-button green-bgr" href={currentPage.relationships.field_board_of_supervisors.field_link.uri.replace('internal:','')} target="_blank" rel="noreferrer">{currentPage.relationships.field_board_of_supervisors.field_link.title}</a>
                    </div>
                  ) : (
                    null
                  )}
                </div>
              </div>
            </div>
          </section>
        ) : (
          null
        )}

        <section className="pwc-channel">
          <div className="container">
            <h2>PWC Channel</h2>
            <div id="pwc-channel" className="uk-position-relative uk-visible-toggle pwc-channel-wrp" tabIndex="-1" data-uk-slider>
              <div className="uk-slider-container">
                <ul className="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-grid uk-grid-match">

                  {currentPage.relationships.field_pwc_channel.relationships.field_youtube_video.map((video, key) => (
                    <li className="yt-modal-video" key={key}>
                      <div className="uk-card uk-flex uk-flex-column" data-uk-lightbox>
                        <a href={"https://www.youtube.com/watch?v=" + video.field_video_id + "?autoplay=1"} aria-label={`open card with ` + video.field_title + ` title`}>
                          <div className="uk-card-media-top" style={{backgroundImage: `url('https://i1.ytimg.com/vi/` + video.field_video_id + `/mqdefault.jpg')`}}></div>
                        </a>
                        <div className="uk-card-body">
                          <div className="video-info">
                            {/*<YtViewCount videoId={video.field_video_id} />&nbsp;&middot;&nbsp;<YtDatePublished videoId={video.field_video_id} />*/}
                          </div>
                          <h3><a href={"https://www.youtube.com/watch?v=" + video.field_video_id + "?autoplay=1"} aria-label={`open card with` + video.field_title + ` name`}>{video.field_title}</a></h3>
                        </div>
                      </div>
                    </li>
                  ))}

                </ul>
              </div>
              {currentPage.relationships.field_pwc_channel.relationships.field_links_buttons.map((button, key) => (
                <div className="button-wrp" key={key}>
                  <a className="uk-button green-bgr" href="https://www.youtube.com/channel/UC3imja0b18Gwy3zmz6MzNPA" target="_blank" rel="noreferrer">{button.field_link.title}</a>
                </div>
              ))}
              <a className="uk-position-center-left-out uk-position-small" href="#" data-uk-slidenav-previous data-uk-slider-item="previous" aria-label="previous item on slider"></a>
              <a className="uk-position-center-right-out uk-position-small" href="#" data-uk-slidenav-next data-uk-slider-item="next" aria-label="next item on slider"></a>
            </div>
          </div>
        </section>

        <section className="questions-and-help">
          <div className="container">
            <div className="uk-grid uk-child-width-1-3@m" data-uk-grid>
              {currentPage.relationships.field_questions.map((question, index) => (
                <div className="questions-and-help-col" key={index}>
                  <h2 className="h3" dangerouslySetInnerHTML={{ __html: question.field_title}}/>
                  <div dangerouslySetInnerHTML={{ __html: question.field_description_wysiwyg.processed}}/>
                  {question.field_repeating_links.length > 0 ? (
                    <div className="button-wrp">
                      {question.field_repeating_links.map((link, indexLink) => (
                        <Link className="uk-button blue-bgr" to={`${link.uri.replace('internal:','')}`} key={indexLink}>{link.title}</Link>
                      ))}
                    </div>
                  ) : (
                    null
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>

      </Layout>
      )
  }


}

export default IndexPage

export const pageQuery = graphql`
  query {
    nodeHomepage(drupal_internal__nid: {eq: 135}) {
      relationships {
        field_board_meetings {
          field_link {
            title
            uri
          }
          field_show_this_meeting
        }
        field_meta_data {
          ...paragraph__meta_dataFragment
        }
        field_board_of_supervisors {
          field_title
          field_title_2
          field_link {
            title
            uri
          }
          field_description_wysiwyg {
            value
            processed
          }
          relationships {
            field_links {
              field_link {
                title
                uri
              }
              relationships {
                field_icon {
                  uri {
                    url
                  }
                }
              }
            }
            field_media_image {
              relationships {
                field_media_image {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
        field_pwc_channel {
          relationships {
            field_links_buttons {
              field_link {
                title
                uri
              }
            }
            field_youtube_video {
              field_title
              field_video_id
            }
          }
        }
        field_questions {
          field_title
          field_description_wysiwyg {
            value
            processed
          }
          field_repeating_links {
            title
            uri
          }
        }

        field_tabbed_lists {
          ... on paragraph__tab_type_1 {
            id
            field_title
            field_tab_icon_name
            relationships {
              field_list_items {
                field_title
                field_description
                relationships {
                  field_icon {
                    filename
                    uri {
                      url
                    }
                  }
                }
                field_link {
                  uri
                }
              }
            }
          }
          ... on paragraph__tab_type_2 {
            id
            field_title
            field_tab_icon_name
            relationships {
              field_list_items_simple {
                field_link {
                  uri
                  title
                }
              }
            }
          }
        }

         field_home_hero {
          field_title_html {
            value
          }
          relationships {
            field_icon {
              uri {
                url
              }
            }
            field_media_image {
              relationships {
                field_media_image {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }

    allNodeNews(filter: {field_global_news_approved: {eq: true}}, limit: 5, sort: {fields: created, order: DESC}) {
      edges {
        node {
          path {
            alias
          }
          title
          relationships {
            field_media_image {
              relationships {
                field_media_image {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
    allNodeEvent(filter: {field_global_news_approved: {eq: true}}, sort: {fields: field_start_date}) {
      edges {
        node {
          field_virtual_event
          field_start_date
          field_end_date
          field_address_line_1
          field_address_line_2
          title
          relationships {
            group_content__departments_group_node_event {
              relationships {
                gid {
                  drupal_internal__id
                  label
                }
              }
            }
          }
          path {
            alias
          }
          title
        }
      }
    }

    allNodeLandingPage(sort: {fields: relationships___field_hero___field_title_html___value}) {
      edges {
        ...node__landing_pageEdgeFragment
      }
    }

    allNodeAlert(filter: {field_global_news_approved: {eq: true}}, sort: {fields: field_weight, order: ASC}) {
      edges {
        node {
          title
          field_alert_text
          field_weight
          field_link {
            title
            uri
          }
          field_alert_type
          field_global_post
          relationships {
            group_content__departments_group_node_alert {
              relationships {
                gid {
                  drupal_internal__id
                }
              }
            }
          }
        }
      }
    }
  }
`
